export const DEFAULT_CURRENCY = 'USD';
export const POINTS_CURRENCY = 'LPT';
export const CURRENCY_KEY = 'currency';
export const AUTH_TOKEN = 'token';

export const SELL_PRIZE_TOOLTIP_SHOWN = 'sell_prize_tooltip';
export const BLOG_POPUP_SHOWN = 'blog_popup';

// make refresh token name not obvious to guess
export const REFRESH_TOKEN = 'urtfidqxsa';
export const HEADER_TOP_KEY = 'header_top';
export const HEADER_BOTTOM_KEY = 'header_bottom';
export const FOOTER_KEY = 'footer';
export const OSRS = 'OSRS';
export const RS3 = 'RS3';
export const CHECKOUT_KEY = 'checkout';
export const DEFAULT_PER_PAGE = 16;

// expire in 30 days, but on SSR it should live only 15 minutes
export const JWT_EXP = 7;
export const REFRESH_EXP = 30;

export const REST_OF_THE_WORLD_CODE = 'other';

export const SUMMARY_PAGE = 'summary';
export const SUCCESS_PAGE = 'success';
export const ERROR_PAGE = 'error';

// product types
export const GOLD = 'gold';
export const ACCOUNT = 'account';
export const PRIZE = 'prize';
export const LOYALTY_POINTS = 'loyalty_points';

export const FORGOT_PASSWORD_MODAL = 'forgot';
export const REGISTER_MODAL = 'register';
export const LOGIN_MODAL = 'login';
export const CUSTOM_MODAL = 'custom';

export const CHECKOUT_SUMMARY_GOLD_URL = '/checkout/summary/gold';

export function isClientSide() {
  return typeof window === 'object';
}
